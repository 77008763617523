<template>
<div>
    <v-dialog v-model="value" fullscreen>
        <v-card>
            <BarTitleDialog title="RELATÓRIO CONSOLIDADO DE CAIXA" @close="close()"></BarTitleDialog>
            <v-card-actions>
                <v-row>
                    <v-col class="text-left">
                        <v-btn outlined rounded x-large color="primary" @click="dialogFilter=true">
                            <v-icon>{{ icons.mdiFilter }}</v-icon>Filtros
                        </v-btn>
                    </v-col>
                    <v-col class="text-right">
                        <v-btn x-large color="secondary" text @click="close()" class="mr-4">Fechar</v-btn>
                        <v-btn :loading="loadingExport" :disabled="loadingExport" x-large color="primary" @click="exportPdf()" class="mr-4">
                            <v-icon>{{ icons.mdiFileExportOutline  }}</v-icon>exportar
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
            <v-divider></v-divider>
            <v-card-text>
                <!-- <ReportConsolidatedCash :data="dataReportConsolidatedCash" /> -->
                <!-- <div  class="text-center mt-4">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                </div> -->

                <v-row>
                    <v-col class="text-right" style="color:black;font-size:25px">
                        Relatório Consolidado de Caixas
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-card outlined style="border:1px solid black">
                            <v-card-text>
                                <v-row no-gutters>
                                    <v-col style="border:1px solid black;border-radius:5px;color:black">
                                        <b class="ml-1">Período: </b> {{formatDateTime(filter.date_start) + ' - ' + formatDateTime(filter.date_end)}}
                                    </v-col>
                                    <v-col class="ml-1 " style="border:1px solid black;border-radius:5px;color:black">
                                        <b class="ml-1">Operador: </b>{{ getUser.name }}
                                    </v-col>
                                </v-row>
                                <v-row no-gutters class="mt-1">
                                    <v-col style="border:1px solid black;border-radius:5px;color:black">
                                        <b class="ml-1">Data Emissão:</b> {{dateEmission() }}
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>

                    </v-col>
                </v-row>
                <v-row class="mt-8" no-gutters>
                    <v-col>
                        <table style="width:100%">
                            <thead>
                                <tr>
                                    <th style=" background:#00599D;color:white">Caixa</th>
                                    <th style="width:300px;background:#00599D;color:white">Usuário</th>
                                    <th style="background:#00599D;color:white">Status</th>
                                    <th style="background:#00599D;color:white">Abertura</th>
                                    <th style="background:#00599D;color:white">Encerramento</th>
                                    <th style="background:#00599D;color:white">Locações</th>
                                    <th style="background:#00599D;color:white"> Total(R$)</th>
                                    <th style="background:#00599D;color:white"> Recebimentos</th>
                                </tr>
                            </thead>
                            <tbody style="color:black">
                                <tr v-for="(item,index) in cashes">
                                    <td>{{item.id}}</td>
                                    <td>{{item.user.name}}</td>
                                    <td></td>
                                    <td>{{ formatDateTime(item.date_opened) }}</td>
                                    <td>{{ formatDateTime(item.date_closed) }}</td>
                                    <td class="text-center">{{ item.qtdAccommodation }}</td>
                                    <td class="text-center">{{formatMoney(item.balance)}}</td>
                                    <td style="font-size:10px"><b>Dinheiro: </b>{{receipt(item).money}},<b> C Débito: </b> {{receipt(item).debit}}, <b>C Crédit:</b> {{receipt(item).credit}}, <b>PIX:</b> {{receipt(item).pix}}  </td>
                                </tr>
                            </tbody>
                            <tfoot style="color:black">
                              <tr>
                                <th colspan="7" class="text-right" style="background:#ADC5E7" >Total:</th>
                                <th style="background:#ADC5E7">{{ formatMoney(sumTotal()) }}</th>
                              </tr>
                            </tfoot>

                        </table>

                    </v-col>

                </v-row>

            </v-card-text>

        </v-card>
    </v-dialog>

    <!-- DIALOG FILTER -->
    <v-dialog v-model="dialogFilter" width="700px">
        <v-card>
            <BarTitleDialog title="Filtros" @close="dialogFilter=false" />
            <v-card-text>
                <v-row>
                  <v-col>
                    <v-card outlined >
                      <v-card-text>
                      <v-row>
                        <v-col>
                            <v-text-field v-model="filter.date_start" hide-details outlined label="Data inicial" type="datetime-local"></v-text-field>
                        </v-col>

                        <v-col>
                            <v-text-field v-model="filter.date_end" hide-details outlined label="Data final" type="datetime-local"></v-text-field>
                        </v-col>
                        </v-row>
                        </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>

            </v-card-text>
            <v-card-actions>
                <v-row>
                    <v-col class="text-left">
                        <v-btn text color="error" x-large @click="clearFilter()">limpar filtros</v-btn>
                    </v-col>

                    <v-col class="text-right">
                        <v-btn text color="secondary" x-large @click="dialogFilter = false">FECHAR</v-btn>
                        <v-btn :loading="loadingFilter" :disabled="loadingFilter" @click="confirmFilter()" color="primary" class="ml-2" x-large>aplicar</v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>

</div>
</template>

<script>
import { mdiFileExportOutline, mdiTools, mdiFilter } from '@mdi/js'
import BarTitleDialog from '@/components/BarTitleDialog.vue'
import { mapActions, mapGetters } from 'vuex'
export default {
  props: {
    value: {
      type: Boolean,
    },
    data: {
      type: Object,
    },
  },
  components: {
    BarTitleDialog,
  },
  data: () => ({
    loadingFilter: false,
    cashes: [],
    filter: {},
    dialogFilter: false,
    loadingExport: false,
    icons: {
      mdiFileExportOutline,
      mdiTools,
      mdiFilter,
    },
  }),
  watch: {
    value() {
      this.periodInitial()
      this.confirmFilter()
    },
  },
  created() {
    this.periodInitial()
    this.confirmFilter()
  },
  computed: {
    ...mapGetters('login', ['getUser']),
    getPeriod() {
      return this.formatDate(this.filter.date_start) + ' - ' + this.formatDate(this.filter.date_end)
    },
  },

  methods: {
    ...mapActions('cash_list', ['getReportConsolidatedCash', 'exportReportConsolidatedCashPdf']),
    sumTotal() {
      return this.cashes.reduce((acc, item) => {
        return acc + parseFloat(item.balance)
      }, 0)
    },
    exportPdf() {
      this.loadingReportCash = true
      this.exportReportConsolidatedCashPdf(this.filter)
        .then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]))
          var fileLink = document.createElement('a')

          fileLink.href = fileURL
          fileLink.setAttribute('download', 'report.pdf')
          document.body.appendChild(fileLink)
          fileLink.click()
        })
        .catch(response => {
          console.log(response)
        })
        .finally(() => {
          this.loadingReportCash = false
        })
    },
    periodInitial() {
      let dateToday = new Date()
      this.filter.date_end = dateToday.toISOString().substring(0, 10)

      dateToday.setDate(dateToday.getDate() - 1)
      this.filter.date_start = dateToday.toISOString().substring(0, 10)
    },
    close() {
      this.$emit('input', false)
    },
    confirmFilter() {
      this.loadingFilter = true
      this.getReportConsolidatedCash(this.filter)
        .then(response => {
          this.cashes = response.data
          this.dialogFilter = false
        })
        .finally(() => {
          this.loadingFilter = false
        })
    },
    clearFilter() {
      this.filter = {}
    },
    receipt(cash) {
      const receipt = cash.receipt
      receipt.money = this.formatMoney(receipt.money)
      receipt.debit = this.formatMoney(receipt.debit)
      receipt.credit = this.formatMoney(receipt.credit)
      receipt.pix = this.formatMoney(receipt.pix)
      return receipt
    },
    formatMoney(value) {
      if (value != null) {
        return value.toLocaleString('pt-br', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      }
    },

    formatDate(date) {
      if (date) {
        const result = new Date(date)
        return result.toLocaleDateString('pt-BR', {
          timeZone: 'UTC',
        })
      }
    },
    formatDateTime(date) {
      if (date) {
        const result = new Date(date)
        return (
          result.toLocaleDateString('pt-BR', {
            timeZone: 'America/Sao_Paulo',
          }) +
          ' ' +
          result.toLocaleTimeString('pt-BR')
        )
      }
    },
    dateEmission() {
      const result = new Date()
      return (
        result.toLocaleDateString('pt-BR', {
          timeZone: 'America/Sao_Paulo',
        }) +
        ' ' +
        result.toLocaleTimeString('pt-BR')
      )
    },
  },
}
</script>

<style scoped>
.box_sencond {
  height: 35px;
  border: 1px solid black;
  border-radius: 5px;
  box-sizing: border-box;
}
</style>
