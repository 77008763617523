<template>
<div>

    <v-row>
        <v-col class="text-right" style="color:black;font-size:25px">
            Relatório Analítico de Caixa
        </v-col>
    </v-row>
    <v-row>
        <v-col>
            <v-card outlined style="border:1px solid black">
                <v-card-text>
                    <v-row no-gutters>
                        <v-col style="border:1px solid black;border-radius:5px;color:black">
                            <b class="ml-1">Caixa:</b> {{data.cash.id}}
                        </v-col>
                        <v-col class="ml-1 " style="border:1px solid black;border-radius:5px;color:black">
                            <b class="ml-1">Operador: </b> {{data.cash.user.name}}
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="mt-1">
                        <v-col style="border:1px solid black;border-radius:5px;color:black">
                            <b class="ml-1">Data Abertura:</b> {{ formatDateTime(data.cash.date_opened) }}
                        </v-col>
                        <v-col class="ml-1" style="border:1px solid black;;border-radius:5px;color:black">
                            <b class="ml-1">Data Fechamento:</b> {{formatDateTime(data.cash.date_closed) }}
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

        </v-col>
    </v-row>

    <v-row>
        <v-col>
            <v-card outlined style="border:1px solid black">
                <v-card-text>
                    <v-row style="color:black">
                        <v-col>
                            <v-row>
                                <v-col>Entradas (Receitas)</v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col class="box_sencond mt-1" style="">
                                    <v-row>
                                        <v-col class="ml-1" style="font-weight:bold">Saldo Inicial:</v-col>
                                        <v-col class="text-right mr-1">R$ {{formatMoney(data.cash.initial_cash)}}</v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col class="box_sencond mt-1" style="">
                                    <v-row>
                                        <v-col class="ml-1" style="font-weight:bold">Quartos:</v-col>
                                        <v-col class="text-right mr-1">R$ {{formatMoney(data.totalAccommodation)}}</v-col>
                                    </v-row>

                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col class="box_sencond mt-1" style="">
                                    <v-row>
                                        <v-col class="ml-1" style="font-weight:bold">Produtos:</v-col>
                                        <v-col class="text-right mr-1">R$ {{formatMoney(data.totalProductSale)}}</v-col>
                                    </v-row>

                                </v-col>
                            </v-row>
                            <v-row no-gutters>

                                <v-col class="box_sencond mt-1" style="">
                                    <v-row>
                                        <v-col class="ml-1" style="font-weight:bold">Serviços:</v-col>
                                        <v-col class="text-right mr-1">R$ {{formatMoney(data.totalServiceSale)}}</v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col class="box_sencond mt-1" style="">
                                    <v-row>
                                        <v-col class="ml-1" style="font-weight:bold">Vendas:</v-col>
                                        <v-col class="text-right mr-1">R$ 0,00</v-col>
                                    </v-row>

                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col class="box_sencond mt-1" style="">
                                    <v-row>
                                        <v-col class="ml-1" style="font-weight:bold">Suprimentos:</v-col>
                                        <v-col class="text-right mr-1">R$ 0,00</v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col class="box_sencond mt-1" style="">
                                    <v-row>
                                        <v-col class="ml-1" style="font-weight:bold">Dup Rec:</v-col>
                                        <v-col class="text-right mr-1">R$ 0,00</v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col class="box_sencond mt-1" style="">
                                    <v-row>
                                        <v-col class="ml-1" style="font-weight:bold">Rec Antec:</v-col>
                                        <v-col class="text-right mr-1">R$ 0,00</v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col class="box_sencond mt-1" style="background:lightgray">
                                    <v-row>
                                        <v-col class="ml-1 " style="font-weight:bold">Saldo:</v-col>
                                        <v-col class="text-right mr-1">R$ {{formatMoney(data.inputBalance)}} </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col>
                            <v-row>
                                <v-col>Formas de Recebimento</v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col class="box_sencond mt-1" style="">
                                    <v-row>
                                        <v-col class="ml-1" style="font-weight:bold">Dinheiro:</v-col>
                                        <v-col class="text-right mr-1">R$ {{formatMoney(data.totalMoney)}}</v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col class="box_sencond mt-1" style="">
                                    <v-row>
                                        <v-col class="ml-1" style="font-weight:bold">C Débito:</v-col>
                                        <v-col class="text-right mr-1">R$ {{formatMoney(data.totalDebit)}}</v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col class="box_sencond mt-1" style="">
                                    <v-row>
                                        <v-col class="ml-1" style="font-weight:bold">C Crédito:</v-col>
                                        <v-col class="text-right mr-1">R$ {{formatMoney(data.totalCredit)}}</v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col class="box_sencond mt-1" style="">
                                    <v-row>
                                        <v-col class="ml-1" style="font-weight:bold">Pix:</v-col>
                                        <v-col class="text-right mr-1">R$ {{formatMoney(data.totalPix)}}</v-col>
                                    </v-row>

                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col class="box_sencond mt-1" style="height:130px;background:#00599D">
                                    <v-row>
                                        <v-col class="text-center mt-4" style="color:white;font-size:20px;font-weight:bold">Saldo Final</v-col>
                                        
                                    </v-row>
                                    <v-row>
                                        <v-col class="text-center" style="color:white;font-size:20px;font-weight:bold">
                                            R$ {{formatMoney(data.balance)}}
                                        </v-col>
                                        
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col>
                            <v-row>
                                <v-col>Saídas (Despesas)</v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col class="box_sencond mt-1" style="">
                                    <v-row>
                                        <v-col class="ml-1" style="font-weight:bold">Compras:</v-col>
                                        <v-col class="text-right mr-1">R$ 0,00</v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col class="box_sencond mt-1" style="">
                                    <v-row>
                                        <v-col class="ml-1" style="font-weight:bold">Sangrias:</v-col>
                                        <v-col class="text-right mr-1">R$ 0,00</v-col>
                                    </v-row>

                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col class="box_sencond mt-1" style="">
                                    <v-row>
                                        <v-col class="ml-1" style="font-weight:bold">Adiant. Func:</v-col>
                                        <v-col class="text-right mr-1">R$ 0,00</v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col class="box_sencond mt-1" style="">
                                    <v-row>
                                        <v-col class="ml-1" style="font-weight:bold">Dup. Pag:</v-col>
                                        <v-col class="text-right mr-1">R$ 0,00</v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col class="box_sencond mt-1" style="">
                                    <v-row>
                                        <v-col class="ml-1" style="font-weight:bold">Despesas:</v-col>
                                        <v-col class="text-right mr-1">R$ 0,00</v-col>
                                    </v-row>

                                </v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col class="box_sencond mt-1" style="background:lightgray">
                                    <v-row>
                                        <v-col class="ml-1" style="font-weight:bold">Saldo:</v-col>
                                        <v-col class="text-right mr-1">R$ 0,00</v-col>
                                    </v-row>
                                </v-col>
                            </v-row>

                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>

    <v-row>
        <v-col>
            <div class="text-center" style="color:black;font-size:25px;font-weight:bold">Cancelamentos</div>
        </v-col>
    </v-row>
    <v-row no-gutters>
        <v-col>
            <table style="width:100%">
                <thead>
                    <tr>
                        <th style="width:100px;background:#00599D;color:white">Reserva ID</th>
                        <th style="width:150px; background:#00599D;color:white">Data</th>
                        <th style="background:#00599D;color:white">Justificativa</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item,index) in data.reserveCanceled " :key="index">
                        <td class="text-center" style="color:black">{{item.id}}</td>
                        <td class="text-center" style="color:black" >{{formatDateTime(item.cancel_date)}}</td>
                        <td class="text-left" style="color:black" >{{ item.justify_cancel}}</td>
                    </tr>
                </tbody>
            </table>
        </v-col>
    </v-row>

 <v-row>
        <v-col>
            <div class="text-center" style="color:black;font-size:25px;font-weight:bold">Transferências</div>
        </v-col>
    </v-row>
    <v-row no-gutters>
        <v-col>
            <table style="width:100%">
                <thead>
                    <tr >
                        <th style="width:150px;background:#00599D;color:white">Data</th>
                        <th style="width:110px; background:#00599D;color:white">Quarto Origem</th>
                        <th style="width:100px;background:#00599D;color:white">Quarto Dest.</th>
                        <th style="background:#00599D;color:white">Justificativa</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item,index) in data.transfRoom" :key="index">
                        <td class="text-center" style="color:black">{{ formatDateTime( item.created_at) }}</td>
                        <td class="text-center" style="color:black" >{{ item.origin_room.description }}</td>
                        <td class="text-center" style="color:black">{{ item.destiny_room.description }}</td>
                        <td class="text-left" style="color:black">{{ item.justification }}</td>
                    </tr>
                </tbody>

            </table>

        </v-col>

    </v-row>

    <v-row>
        <v-col>
            <div class="text-center" style="color:black;font-size:25px;font-weight:bold">Lançados - Mão-de-obra extra e demais despesas</div>
        </v-col>
    </v-row>
    <v-row no-gutters>
        <v-col>
            <table style="width:100%">
                <thead>
                    <tr>
                        <th style="width:200px; background:#00599D;color:white">Solicitante</th>
                        <th style="width:200px;background:#00599D;color:white">Prestador</th>
                        <th style="width:200px;background:#00599D;color:white">Descrição</th>
                        <th style="width:200px;background:#00599D;color:white">Valor</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </tbody>

            </table>

        </v-col>

    </v-row>

    <v-row>
        <v-col>
            <div class="text-center" style="color:black;font-size:25px;font-weight:bold">Lançados - Consumo Interno</div>
        </v-col>
    </v-row>
    <v-row no-gutters>
        <v-col>
            <table style="width:100%">
                <thead>
                    <tr>
                        <th style=" background:#00599D;color:white">Consumidor</th>
                        <th style="background:#00599D;color:white">Código</th>
                        <th style="background:#00599D;color:white">Produto/Item</th>
                        <th style="background:#00599D;color:white">Qtde. Item</th>
                        <th style="background:#00599D;color:white">Valor de custo</th>
                        <th style="width:100px;background:#00599D;color:white">Data/Hora</th>
                    </tr>
                </thead>
                <tbody style="font-size:9px;color:black">
                    <tr v-for="(item,index) in data.intern_consumption" key="index">
                        <td class="text-center">{{item.consumer}}</td>
                        <td class="text-center">{{item.product.code}}</td>
                        <td class="text-center">{{item.product.description}}</td>
                        <td class="text-center">{{item.quantity}}</td>
                        <td class="text-center">{{ formatMoney(item.sale_price)}}</td>
                        <td class="text-center">{{ formatDateTime(item.created_at)}}</td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td colspan="5" class="text-right" style="background:#ADC5E7;color:black;font-weight:bold">Total(R$):</td>
                        <td style="background:#ADC5E7;color:black;font-weight:bold;text-align:right"> {{formatMoney(data.total_intern_consumption)}}</td>
                    </tr>
                </tfoot>
            </table>

        </v-col>

    </v-row>

    <v-row>
        <v-col>
            <div class="text-center" style="color:black;font-size:25px;font-weight:bold">Locações no Caixa</div>
        </v-col>
    </v-row>
    <v-row no-gutters>
        <v-col>
            <table style="width:100%">
                <thead>
                    <tr style="font-size:9px;height:21px">
                        <th style=" background:#00599D;color:white">Suíte</th>
                        <th style="background:#00599D;color:white">Categoria</th>
                        <th style="width:100px;background:#00599D;color:white">Início</th>
                        <th style="width:100px;background:#00599D;color:white">Fim</th>
                        <th style="background:#00599D;color:white">Consumo(R$)</th>
                        <th style="background:#00599D;color:white"> Locação(R$)</th>
                        <th style="width:80px;background:#00599D;color:white">Acréscimos(+)</th>
                        <th style="width:70px;background:#00599D;color:white">Descontos(-)</th>
                        <th style="width:70px;background:#00599D;color:white">Imp.</th>
                        <th style="width:70px;background:#00599D;color:white">Status</th>
                        <th style="width:70px;background:#00599D;color:white">Cortesia </th>
                        <th style="width:70px;background:#00599D;color:white">Valor(R$) </th>
                    </tr>
                </thead>
                <tbody style="font-size:9px;color:black">
                    
                    <tr v-for="(item,index) in data.accommodationCash" key="index">
                        <td class="text-center">{{item.room}}</td>
                        <td class="text-center">{{item.category}}</td>
                        <td>{{formatDateTime(item.checkin)}}</td>
                        <td>{{formatDateTime(item.checkout)}}</td>
                        <td class="text-center">{{formatMoney(item.consumption)}}</td>
                        <td class="text-center">{{ formatMoney(item.accommodation)}}</td>
                        <td class="text-center">{{formatMoney(item.additional)}}</td>
                        <td class="text-center">{{formatMoney(item.discount)}}</td>
                        <td class="text-center">{{ formatMoney(item.tax)}}</td>
                        <td class="text-center">{{item.status}}</td>
                        <td class="text-center">Não</td>
                        <td class="text-right">{{formatMoney(item.value)}}</td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td colspan="11" class="text-right" style="background:#ADC5E7;color:black;font-weight:bold">Total(R$):</td>
                        <td style="background:#ADC5E7;color:black;font-weight:bold;text-align:right"> {{formatMoney(data.totalAccommodationCash)}}</td>
                    </tr>
                </tfoot>
            </table>

        </v-col>

    </v-row>

    <v-row>
        <v-col>
            <div class="text-center" style="color:black;font-size:25px;font-weight:bold">Locações por Categoria</div>
        </v-col>
    </v-row>
    <v-row no-gutters>
        <v-col>
            <table style="width:100%">
                <thead>
                    <tr>
                        <th style="width:500px; background:#00599D;color:white">Categoria</th>
                        <th style="background:#00599D;color:white">Quantidade</th>
                        <th style="background:#00599D;color:white">Valor (R$)</th>
                    </tr>
                </thead>
                <tbody class="" style="color:black">
                    <tr v-for="(item,index) in data.accommodationPerCategory" key="index">
                        <td>{{item.category}}</td>
                        <td class="text-center">{{item.quantity}}</td>
                        <td class="text-right">{{ formatMoney(item.total)}}</td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td colspan="2" style="background:#ADC5E7;color:black;font-weight:bold">Total (R$):</td>
                        <td style="background:#ADC5E7;color:black;font-weight:bold;text-align:right">{{ formatMoney(data.totalAccommodationPerCategory)}}</td>
                    </tr>
                </tfoot>
            </table>
        </v-col>
    </v-row>

    <v-row>
        <v-col>
            <div class="text-center" style="color:black;font-size:25px;font-weight:bold">Produtos Vendidos
            </div>
        </v-col>
    </v-row>
    <v-row no-gutters>
        <v-col>
            <table style="width:100%">
                <thead>
                    <tr>
                        <th style=" width:500px;background:#00599D;color:white">Produto</th>
                        <th style="width:100px;background:#00599D;color:white">Quantidade</th>
                        <th style="background:#00599D;color:white">Valor (R$)</th>
                    </tr>
                </thead>
                <tbody style="color:black">
                    <tr v-for="(item,index) in data.productSale" key="index">
                        <td>{{item.product}}</td>
                        <td class="text-center">{{item.quantity}}</td>
                        <td class="text-right" style="width:100px">{{formatMoney(item.value)}}</td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td colspan="2" class="text-right" style="background:#ADC5E7;color:black;font-weight:bold">Total(R$):</td>
                        <td style="background:#ADC5E7;color:black;font-weight:bold;text-align:right"> {{formatMoney(data.totalProductSale)}}</td>
                    </tr>
                </tfoot>
            </table>

        </v-col>

    </v-row>

    <v-row>
        <v-col>
            <div class="text-center" style="color:black;font-size:25px;font-weight:bold">Produtos Cancelados
            </div>
        </v-col>
    </v-row>
    <v-row no-gutters>
        <v-col>
            <table style="width:100%">
                <thead>
                    <tr>
                        <th style=" background:#00599D;color:white">Código do item</th>
                        <th style="width:300px;background:#00599D;color:white">Descrição</th>
                        <th style="background:#00599D;color:white">Quantidade Data/Hora</th>
                        <th style="background:#00599D;color:white">Autorizado por</th>
                        <th style="background:#00599D;color:white">Justificativa</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </tbody>

            </table>

        </v-col>

    </v-row>

    <v-row>
        <v-col>
            <div class="text-center" style="color:black;font-size:25px;font-weight:bold">Serviços Vendidos
            </div>
        </v-col>
    </v-row>
    <v-row no-gutters>
        <v-col>
            <table style="width:100%">
                <thead>
                    <tr>
                        <th style=" width:500px;background:#00599D;color:white">Serviço</th>
                        <th style="width:100px;background:#00599D;color:white">Quantidade</th>
                        <th style="background:#00599D;color:white">Valor (R$)</th>
                    </tr>
                </thead>
                <tbody style="color:black">
                    <tr v-for="(item,index) in data.serviceSale" key="index">
                        <td>{{item.service}}</td>
                        <td class="text-center">{{item.quantity}}</td>
                        <td class="text-right">{{formatMoney(item.value)}}</td>

                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td colspan="2" class="text-right" style="background:#ADC5E7;color:black;font-weight:bold">Total(R$):</td>
                        <td style="background:#ADC5E7;color:black;font-weight:bold;text-align:right"> 0,00</td>
                    </tr>
                </tfoot>
            </table>

        </v-col>

    </v-row>

    <v-row>
        <v-col>
            <div class="text-center" style="color:black;font-size:25px;font-weight:bold">Acréscimos e Descontos Registrados no Caixa
            </div>
        </v-col>
    </v-row>
    <v-row no-gutters>
        <v-col>
            <table style="width:100%">
                <thead>
                    <tr>
                        <th style=" background:#00599D;color:white">Comanda</th>
                        <th style="background:#00599D;color:white">Suite</th>
                        <th style="background:#00599D;color:white">Inicio</th>
                        <th style="background:#00599D;color:white">Fim</th>
                        <th style="background:#00599D;color:white">Acréscimo</th>
                        <th style="background:#00599D;color:white">Justificativa</th>

                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </tbody>

            </table>

        </v-col>

    </v-row>

    <v-row>
        <v-col>
            <div class="text-center">
                <v-row no-gutters style="color:black;font-size:25px;font-weight:bold">
                    <v-col>Recebimentos Antecipados</v-col>
                </v-row>
                <v-row no-gutters style="color:red;font-size:10px;">
                    <v-col>Os valores serão lançados no caixa que encerrar a conta </v-col>
                </v-row>

            </div>
        </v-col>
    </v-row>
    <v-row no-gutters>
        <v-col>
            <table style="width:100%">
                <thead>
                    <tr>
                        <th style=" background:#00599D;color:white">Comanda</th>
                        <th style="background:#00599D;color:white">Tipo recebido Valor (R$)</th>
                        <th style="background:#00599D;color:white">Autenticação</th>
                        <th style="background:#00599D;color:white">Data/Hora</th>

                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </tbody>

            </table>

        </v-col>

    </v-row>
</div>
</template>
<script>
export default {
  props: {
    data: {
      type: Object,
    },
  },

  methods: {
    formatDateTime(date) {
      if (date) {
        const result = new Date(date)
        return (
          result.toLocaleDateString('pt-BR', {
            timeZone: 'America/Sao_Paulo',
          }) +
          ' ' +
          result.toLocaleTimeString('pt-BR')
        )
      }
    },
    formatDate(date) {
      if (date) {
        const result = new Date(date)
        return result.toLocaleDateString('pt-BR', {
          timeZone: 'America/Sao_Paulo',
        })
      }
    },
    formatMoney(value) {
      value = parseFloat(value)
      if (value != null) {
        return value.toLocaleString('pt-br', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      }
    },
  },
}
</script>

<style scoped>
.box_sencond {
  height: 35px;
  border: 1px solid black;
  border-radius: 5px;
  box-sizing: border-box;
}
</style>
