<template>
<div>
    <v-data-table :headers="headers" :items="cashes" :loading="loading" class="elevation-1" :page-count="numberOfPagesPaginate" :page="currentPagePaginate" :server-items-length="totalStagesPaginate" :options.sync="options" :footer-props="{
        itemsPerPageText: 'Itens por pagina',
        itemsPerPageOptions: [5, 10, 15],
        showFirstLastPage: true,
      }">
        <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title>CAIXAS</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                
                <v-menu :offset-y="true">
                    <template v-slot:activator="{ on: menu, attrs }">
                        <v-tooltip top>
                            <template v-slot:activator="{ on: tooltip }">
                                <v-btn color="primary" outlined icon large v-bind="attrs" v-on="{ ...tooltip, ...menu }">
                                    <v-icon>
                                        {{ icons.mdiTools }}
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>Ferramentas</span>
                        </v-tooltip>
                    </template>
                    <v-list>
                        <v-list-item @click="showReportConsolidatedCash()">
                            <v-list-item-title>
                                <v-icon>{{ icons.mdiFileChartOutline  }}</v-icon> Rel. Consolidado de Caixas
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
                
                <v-spacer></v-spacer>
                
                <v-btn v-if="Object.keys(cash).length == 0" :loading="loadingBtnCash" :disabled="loadingBtnCash" @click="showOpenCash()" color="primary" rounded class="mb-2 col-3">
                    <v-icon>{{ icons.mdiPlus }} </v-icon>Abrir Novo Caixa
                </v-btn>
                <v-btn v-else @click="viewOpenCash()" color="secondary" rounded dark class="mb-2 col-3">
                    Ver Caixa Aberto
                </v-btn>

            </v-toolbar>
        </template>
        <template v-slot:item.date_opened="{ item }">
            {{ formatDateTime(item.date_opened)}}
        </template>

        <template v-slot:item.date_closed="{ item }">
            {{ formatDateTime(item.date_closed)}}
        </template>

        <template v-slot:item.initial_cash="{ item }">
            {{ formatMoney(item.initial_cash)}}
        </template>
        <template v-slot:item.status="{ item }">
            <v-chip v-if="item.open=='not' && item.verified=='not'" color="error">Fechado</v-chip>
            <v-chip v-if="item.open=='yes'&& item.verified=='not'" color="success">Aberto</v-chip>
            <v-chip v-if="item.open=='not'&& item.verified=='yes'" color="info">Verificado</v-chip>
        </template>
        <template v-slot:item.actions="{ item }">
            <div style="white-space: nowrap">
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon color="primary" @click="showDetails(item)" v-bind="attrs" v-on="on">
                            <v-icon>
                                {{icons.mdiNoteOutline }}
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>Detalhes</span>
                </v-tooltip>
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-if="item.verified=='yes'" icon color="primary" class="ml-1" v-bind="attrs" v-on="on">
                            <v-icon>
                                {{icons.mdiCheck }}
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>Verificado</span>
                </v-tooltip>
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-if="item.verified=='not' " @click="sendVerified(item)" icon color="success" class="ml-1" v-bind="attrs" v-on="on">
                            <v-icon>
                                {{icons.mdiThumbUpOutline }}
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>Confirmar Verificação</span>
                </v-tooltip>

                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn @click="showReportCash(item)" icon color="secondary" class="ml-1" v-bind="attrs" v-on="on">
                            <v-icon>
                                {{ icons.mdiFileChartOutline  }}
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>Rel. Analítico</span>
                </v-tooltip>
            </div>
        </template>
        <template v-slot:no-data>
            <NoDataTable></NoDataTable>
        </template>
    </v-data-table>
    <v-dialog v-model="dialogPassword" width="500px">

        <v-card>
            <BarTitleDialog title="Senha" @close="dialogPassword=false"></BarTitleDialog>
            <v-card-text>
                <v-row no-gutters>
                    <v-col>
                        <v-text-field v-model="login.email" :error-messages="errorEmail" outlined label="Email"></v-text-field>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col>
                        <v-text-field v-model="login.password" type="password" :error-messages="errorPassword" outlined label="Senha"></v-text-field>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-row>
                    <v-col class="text-right">
                        <v-btn x-large color="secondary" @click="dialogPassword=false" text class="mr-4">Fechar</v-btn>
                        <v-btn x-large :loading="loadingVerified" :disabled="loadingVerified" color="primary" @click="confirmVerified()">Confirmar</v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <!-- DIALOG RELATORIO ANALITICO CAIXA  -->

    <v-dialog v-model="dialogReportCash" width="900px">
        <v-card>
            <BarTitleDialog title="RELATÓRIO ANALÍTICO" @close="dialogReportCash=false"></BarTitleDialog>
            <v-card-actions>
                <v-row>
                    <v-col class="">
                        <v-btn :loading="loadingReportCash" :disabled="loadingReportCash" x-large color="secondary" @click="reportCash(item)" class="mr-4">
                            <v-icon>{{ icons.mdiFileExportOutline  }}</v-icon>exportar
                        </v-btn>
                        <!-- <v-btn x-large :loading="loadingVerified" :disabled="loadingVerified" color="primary" @click="confirmVerified()">Confirmar</v-btn> -->
                    </v-col>
                    <v-col class="text-right">
                        <v-btn x-large color="secondary" text @click="dialogReportCash=false" class="mr-4">Fechar</v-btn>
                        <!-- <v-btn x-large :loading="loadingVerified" :disabled="loadingVerified" color="primary" @click="confirmVerified()">Confirmar</v-btn> -->
                    </v-col>
                </v-row>
            </v-card-actions>
            <v-divider></v-divider>
            <v-card-text>
                <ReportAnalytical v-if="!loadingReportAnalytical" :data="dataReportAnalitical" />
                <div v-else class="text-center mt-4">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                </div>

            </v-card-text>

        </v-card>
    </v-dialog>

     <!-- DIALOG RELATORIO CONSOLIDADO DE CAIXA  -->
     <ReportConsolidatedCash v-model="dialogReportConsolidatedCash"/> 
    
    <ModalOpenCash v-model="showModalOpenCash" />
</div>
</template>

<script>
import {
  mdiCheck,
  mdiNoteOutline,
  mdiThumbUpOutline,
  mdiPlus,
  mdiFileChartOutline,
  mdiFileExportOutline,
  mdiTools,
} from '@mdi/js'
import NoDataTable from '@/components/NoDataTable.vue'
import BarTitleDialog from '@/components/BarTitleDialog.vue'
import ModalOpenCash from './_components/ModalOpenCash.vue'
import ReportAnalytical from './_components/ReportAnalitical.vue'
import ReportConsolidatedCash from './_components/ReportConsolidatedCash.vue'
import { mapActions } from 'vuex'

export default {
  components: {
    NoDataTable,
    BarTitleDialog,
    ModalOpenCash,
    ReportAnalytical,
    ReportConsolidatedCash,
  },
  data: () => ({
    dialogReportConsolidatedCash: false,

    //dialog cash analitical
    loadingReportCash: false,
    loadingReportAnalytical: false,
    reportCashAnalitical: {},
    dialogReportCash: false,
    dataReportAnalitical: {},

    loadingBtnCash: false,
    showModalOpenCash: false,
    errorPassword: null,
    errorEmail: null,
    loadingVerified: false,
    dialogPassword: false,
    options: {},
    totalStagesPaginate: 0,
    numberOfPagesPaginate: 0,
    currentPagePaginate: 0,
    loading: false,
    cashes: [],
    cash: {},
    editedIndex: null,
    editedItem: null,
    login: {},
    headers: [
      {
        text: 'ID',
        align: 'start',
        sortable: false,
        value: 'id',
      },
      {
        text: 'USUÁRIO',
        align: 'start',
        value: 'user.name',
      },
      {
        text: 'DATA ABERTURA',
        align: 'center',
        value: 'date_opened',
      },
      {
        text: 'DATA ENCERRAMENTO',
        align: 'start',
        value: 'date_closed',
      },
      {
        text: 'CÉLULA TROCO',
        align: 'right',
        value: 'initial_cash',
      },
      {
        text: 'STATUS',
        align: 'center',
        value: 'status',
      },
      {
        text: '',
        align: 'end',
        value: 'actions',
      },
    ],

    search: '',
    icons: {
      mdiNoteOutline,
      mdiCheck,
      mdiThumbUpOutline,
      mdiPlus,
      mdiFileChartOutline,
      mdiFileExportOutline,
      mdiTools,
    },
  }),

  created() {
    this.initialize()
  },
  watch: {
    options: {
      handler() {
        this.initialize()
      },
    },
  },
  computed: {},

  methods: {
    ...mapActions('cash_list', ['getCashes', 'storeVerified', 'reportAnalytical', 'viewReportAnalytical']),
    ...mapActions('cash', ['getCash']),
    initialize() {
      //verifica se tem caixa aberto

      this.loadingBtnCash = true
      this.getCash()
        .then(response => {
          this.cash = response.data
        })
        .finally(() => {
          this.loadingBtnCash = false
        })

      const { page, itemsPerPage } = this.options

      const payload = {
        itemsPerPage: itemsPerPage || 10,
        page,
        search: this.search,
      }

      this.loading = true
      this.getCashes(payload)
        .then(response => {
          this.cashes = response.data.data
          this.totalStagesPaginate = response.data.total
          this.numberOfPagesPaginate = response.data.last_page
          this.currentPagePaginate = response.data.current_page
        })
        .finally(() => {
          this.loading = false
        })
    },
    showReportConsolidatedCash() {
      this.dialogReportConsolidatedCash = true
    },
    showReportCash(cash) {
      this.dialogReportCash = true
      this.loadingReportAnalytical = true
      this.viewReportAnalytical(cash.id)
        .then(response => {
          this.dataReportAnalitical = response.data
        })
        .finally(() => {
          this.loadingReportAnalytical = false
        })
      this.reportCashAnalitical = cash
    },
    reportCash() {
      this.loadingReportCash = true
      this.reportAnalytical(this.reportCashAnalitical.id)
        .then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]))
          var fileLink = document.createElement('a')

          fileLink.href = fileURL
          fileLink.setAttribute('download', 'report.pdf')
          document.body.appendChild(fileLink)
          fileLink.click()
        })
        .catch(response => {
          console.log(response)
        })
        .finally(() => {
          this.loadingReportCash = false
        })
    },
    showDetails(item) {
      this.$router.push('/cash_list/detalhes/' + item.id)
    },
    sendVerified(item) {
      this.dialogPassword = true
      this.editedIndex = this.cashes.indexOf(item)
      this.editedItem = item
      this.login = {}
    },
    showOpenCash() {
      this.showModalOpenCash = true
    },
    viewOpenCash() {
      this.$router.push('/cash')
    },
    confirmVerified() {
      if (this.login.email == '' || this.login.email == undefined) {
        this.errorEmail = 'Email é campo ebrigatório'
        return false
      }

      if (this.login.password == '' || this.login.password == undefined) {
        this.errorPassword = 'Senha é campo ebrigatório'
        return false
      }

      const data = {
        cash_id: this.editedItem.id,
        email: this.login.email,
        password: this.login.password,
      }
      this.loadingVerified = true
      this.storeVerified(data)
        .then(response => {
          Object.assign(this.cashes[this.editedIndex], response.data)

          this.dialogPassword = false
          this.$toast.success('Salvo com sucesso')
        })
        .catch(error => {
          if (error.response.status == '500') {
            this.$toast.error('Erro no servidor')
          }
          if (error.response.status == '422') {
            this.$toast.error(error.response.data)
          }
        })
        .finally(() => {
          this.loadingVerified = false
        })
    },
    formatMoney(value) {
      if (value != null) {
        return value.toLocaleString('pt-br', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      }
    },
    formatDate(date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    formatDateTime(date) {
      if (date) {
        const result = new Date(date)
        return (
          result.toLocaleDateString('pt-BR', {
            timeZone: 'America/Sao_Paulo',
          }) +
          ' ' +
          result.toLocaleTimeString('pt-BR')
        )
      }
    },
  },
}
</script>

<style scoped>
</style>
