<template>
  <div>
    <v-dialog v-model="dialog" max-width="600px">

      <v-card>
        <bar-title-dialog title="Abrir Caixa" @close='dialog=false'></bar-title-dialog>

        <v-card-text>
          <v-row no-gutters>
            <v-col>
              <vuetify-money x-large v-model="initialCash" class=" offset-3 col-6 " outlined label="Valor" />
            </v-col>
          </v-row>


        </v-card-text>
           <v-divider></v-divider>
        <v-card-actions>
          <v-row no-gutters>
            <v-col>

              <v-btn  color="primary" x-large dark class=" float-right" @click="storeOpenCash()">
                Abrir CAixa
              </v-btn>

              <v-btn @click="dialog=false" color="secondary" text  x-large  class=" float-right mr-2 ">
                Fechar
              </v-btn>

            </v-col>
          </v-row>
        </v-card-actions>

      </v-card>
    </v-dialog>



  </div>
</template>

<script>

import {
  mdiWindowClose,
} from '@mdi/js'

import { mapActions, mapMutations } from 'vuex'
import BarTitleDialog from '@/components/BarTitleDialog.vue'

export default {
  props: {
    value: { type: Boolean }
  },
  components: {
    BarTitleDialog
  },
  watch: {
    value(val) {
      this.dialog = val;
    },
    dialog(val) {
      this.$emit('input',val)
    }
  },
  data: () => ({
    dialog:false,
    notBills: false,
    showModalBillToPay: false,
    initialCash: 0,
    icons: {
      mdiWindowClose,
    },
  }),
  methods: {
    ...mapActions('cash', ['openCash']),
    ...mapMutations('cash', ['setCash']),

    storeOpenCash() {
      this.openCash(this.initialCash)
        .then((response) => {
          this.setCash(response.data)
          this.$router.push('cash')
        }).catch()
        .finally()
    },
  }
}
</script>

